@tailwind base;
@tailwind components;
@tailwind utilities;
/* @font-face {
  font-family: 'Amithen';
  src: local('Amithen') url('./assets/fonts/Amithen/Amithen.ttf')
    format('truetype');
} */
/* @font-face {
  font-family: 'JetBrainsMono';
  src: local('JetBrainsMono')
    url('./assets/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf')
    format('truetype');
} 

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.font-amithen {
  font-family: "Amithen", sans-serif;
}

.social-btn {
  @apply shadow-lg py-3 px-3 rounded-full transition duration-300;
}

.facebook-btn {
  @apply bg-sct-white text-facebook-bg shadow-facebook-bg hover:bg-facebook-bg hover:text-white;
}

.twitter-btn {
  @apply bg-sct-white text-twitter-bg shadow-twitter-bg hover:bg-twitter-bg hover:text-white;
}

.youtube-btn {
  @apply bg-sct-white text-youtube-bg shadow-youtube-bg hover:bg-youtube-bg hover:text-white;
}

.linkedin-btn {
  @apply bg-sct-white text-linkedin-bg shadow-linkedin-bg hover:bg-linkedin-bg hover:text-white;
}

.instagram-btn {
  @apply bg-sct-white text-instagram-bg shadow-instagram-bg hover:bg-instagram-bg hover:text-white;
}

.nav-active {
  @apply text-sct-primary;
}

.nav-sm-active {
  @apply bg-sct-primary text-white;
}
a{
color: blueviolet;
}

.image_wrapper {
  margin: 0px 100px;
}
.image_wrapper img {
  width: 10%;
}

.image_wrapper {
  margin: 0px 30px;
}

.image_wrapper img {
  width: 80%;
}
