.card-one {
  /* background: linear-gradient(#3ab990, #0da463); */
  background: linear-gradient(#28057c, #6a0ae7);
}

.card-two {
  /* background: linear-gradient(#00b8f8, #0062eb); */
  background: linear-gradient(#28057c, #6a0ae7);
}

.card-three {
  /* background: linear-gradient(#fd5590, #f86c67); */
  background: linear-gradient(#28057c, #6a0ae7);
}
